import '../css/index.scss'
import $ from 'jquery'
// import anime from 'animejs'
import _ from 'lodash';

'use strict';

window.addEventListener('load',(event)=>{

    document.documentElement.classList.add("is-loaded"),
    document.documentElement.classList.remove("is-loading"),
    setTimeout((function() {
        document.documentElement.classList.add("is-ready");

        $(function(){
            //URL末尾にハッシュがある場合
            const urlHash = location.hash;
            if(urlHash) {
                $('body,html').stop().scrollTop(0);
                setTimeout(function(){
                    const target = $(urlHash);
                    const anchorPos = target.offset().top;
                    locoScroll.scrollTo(anchorPos);
                });
            }
        });

    }), 300);

});

$(function(){

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // drawer-nav

    var gNavi = $('.drawer-nav');
    var gNaviBG = $('.drawer-nav-overlay');
    var btnHamburger = $('.button-toggle');

    $('.js-drawer-nav').on('click', function(){
        btnHamburger.toggleClass('-active');
        gNavi.toggleClass('-active');
        gNaviBG.toggleClass('-active');
        $('.c-header__logo').toggleClass('-active');
    });

    $('.drawer-nav-overlay').on('click', function(){
        btnHamburger.toggleClass('-active');
        gNavi.toggleClass('-active');
        gNaviBG.toggleClass('-active');
        $('.c-header__logo').toggleClass('-active');
    });

    // langSwitch
    $('.js-langSwitch').on('click', function(){
        event.preventDefault();
        var path = location.pathname;
        var lang = $(this).data('lang');

        if( lang == 'ja' ) {
        $(this).attr('data-lang', 'en');
        path = '/en' + path ;
        setTimeout(function() { location.href = path }, 500);
        } else {
        $(this).attr('data-lang', 'ja');
        path = path.slice(3);
        setTimeout(function() { location.href = path }, 500);
        }
    });

    // scroll.on('scroll', (args) => {
    //     var y = args.delta['y'];
    //     if( y == 0 ) { document.documentElement.classList.add("is-top") }
    //     else { document.documentElement.classList.remove("is-top") }
    // });

});

